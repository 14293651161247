export var TroubleshootingLabel;
(function (TroubleshootingLabel) {
    TroubleshootingLabel["VISITOR_SEND_HIT"] = "VISITOR_SEND_HIT";
    TroubleshootingLabel["VISITOR_FETCH_CAMPAIGNS_ERROR"] = "VISITOR_FETCH_CAMPAIGNS_ERROR";
    TroubleshootingLabel["VISITOR_FETCH_CAMPAIGNS"] = "VISITOR_FETCH_CAMPAIGNS";
    TroubleshootingLabel["VISITOR_AUTHENTICATE"] = "VISITOR_AUTHENTICATE";
    TroubleshootingLabel["VISITOR_UNAUTHENTICATE"] = "VISITOR_UNAUTHENTICATE";
    TroubleshootingLabel["VISITOR_EXPOSED_FLAG_NOT_FOUND"] = "VISITOR_EXPOSED_FLAG_NOT_FOUND";
    TroubleshootingLabel["GET_FLAG_VALUE_FLAG_NOT_FOUND"] = "GET_FLAG_VALUE_FLAG_NOT_FOUND";
    TroubleshootingLabel["GET_FLAG_METADATA_TYPE_WARNING"] = "GET_FLAG_METADATA_TYPE_WARNING";
    TroubleshootingLabel["GET_FLAG_VALUE_TYPE_WARNING"] = "GET_FLAG_VALUE_TYPE_WARNING";
    TroubleshootingLabel["VISITOR_EXPOSED_TYPE_WARNING"] = "VISITOR_EXPOSED_TYPE_WARNING";
    TroubleshootingLabel["VISITOR_SEND_ACTIVATE"] = "VISITOR_SEND_ACTIVATE";
    TroubleshootingLabel["GET_CAMPAIGNS_ROUTE_RESPONSE_ERROR"] = "GET_CAMPAIGNS_ROUTE_RESPONSE_ERROR";
    TroubleshootingLabel["GET_CAMPAIGNS_ROUTE_RESPONSE"] = "GET_CAMPAIGNS_ROUTE_RESPONSE";
    TroubleshootingLabel["SDK_BUCKETING_FILE"] = "SDK_BUCKETING_FILE";
    TroubleshootingLabel["SEND_ACTIVATE_HIT_ROUTE_ERROR"] = "SEND_ACTIVATE_HIT_ROUTE_ERROR";
    TroubleshootingLabel["SEND_BATCH_HIT_ROUTE_RESPONSE_ERROR"] = "SEND_BATCH_HIT_ROUTE_RESPONSE_ERROR";
    TroubleshootingLabel["SEND_HIT_ROUTE_ERROR"] = "SEND_HIT_ROUTE_ERROR";
    TroubleshootingLabel["SDK_BUCKETING_FILE_ERROR"] = "SDK_BUCKETING_FILE_ERROR";
    TroubleshootingLabel["SDK_CONFIG"] = "SDK_CONFIG";
})(TroubleshootingLabel || (TroubleshootingLabel = {}));
