import { BASE_API_URL, EXPOSE_ALL_KEYS, FETCH_FLAGS_PANIC_MODE, FlagshipStatus, HEADER_APPLICATION_JSON, HEADER_CONTENT_TYPE, HEADER_X_API_KEY, HEADER_X_SDK_CLIENT, HEADER_X_SDK_VERSION, LogLevel, PROCESS_FETCHING_FLAGS, SDK_INFO, URL_CAMPAIGNS } from '../enum/index';
import { TroubleshootingLabel } from '../types';
import { errorFormat, logDebug } from '../utils/utils';
import { Troubleshooting } from '../hit/Troubleshooting';
export class DecisionManager {
    _config;
    _panic = false;
    _httpClient;
    _statusChangedCallback;
    _troubleshooting;
    _lastBucketingTimestamp;
    _trackingManager;
    _flagshipInstanceId;
    get trackingManager() {
        return this._trackingManager;
    }
    set trackingManager(v) {
        this._trackingManager = v;
    }
    get flagshipInstanceId() {
        return this._flagshipInstanceId;
    }
    set flagshipInstanceId(v) {
        this._flagshipInstanceId = v;
    }
    get lastBucketingTimestamp() {
        return this._lastBucketingTimestamp;
    }
    get troubleshooting() {
        return this._troubleshooting;
    }
    set troubleshooting(v) {
        this._troubleshooting = v;
    }
    get config() {
        return this._config;
    }
    // eslint-disable-next-line accessor-pairs
    set panic(v) {
        this.updateFlagshipStatus(v ? FlagshipStatus.READY_PANIC_ON : FlagshipStatus.READY);
        this._panic = v;
        if (v) {
            logDebug(this.config, FETCH_FLAGS_PANIC_MODE, PROCESS_FETCHING_FLAGS);
        }
    }
    statusChangedCallback(v) {
        this._statusChangedCallback = v;
    }
    constructor(httpClient, config) {
        this._config = config;
        this._httpClient = httpClient;
    }
    updateFlagshipStatus(v) {
        if (typeof this._statusChangedCallback === 'function' && this._statusChangedCallback) {
            this._statusChangedCallback(v);
        }
    }
    getModifications(campaigns) {
        const modifications = new Map();
        campaigns.forEach((campaign) => {
            const object = campaign.variation.modifications.value;
            for (const key in object) {
                const value = object[key];
                modifications.set(key, {
                    key,
                    campaignId: campaign.id,
                    campaignName: campaign.name || '',
                    variationGroupId: campaign.variationGroupId,
                    variationGroupName: campaign.variationGroupName || '',
                    variationId: campaign.variation.id,
                    variationName: campaign.variation.name || '',
                    isReference: !!campaign.variation.reference,
                    campaignType: campaign.type,
                    slug: campaign.slug,
                    value
                });
            }
        });
        return modifications;
    }
    isPanic() {
        return this._panic;
    }
    async getDecisionApiCampaignsAsync(visitor) {
        const headers = {
            [HEADER_X_API_KEY]: `${this.config.apiKey}`,
            [HEADER_X_SDK_CLIENT]: SDK_INFO.name,
            [HEADER_X_SDK_VERSION]: SDK_INFO.version,
            [HEADER_CONTENT_TYPE]: HEADER_APPLICATION_JSON
        };
        const requestBody = {
            visitorId: visitor.visitorId,
            anonymousId: visitor.anonymousId,
            trigger_hit: false,
            context: visitor.context,
            visitor_consent: visitor.hasConsented
        };
        const url = `${this.config.decisionApiUrl || BASE_API_URL}${this.config.envId}${URL_CAMPAIGNS}?${EXPOSE_ALL_KEYS}=true&extras[]=accountSettings`;
        const now = Date.now();
        try {
            const response = await this._httpClient.postAsync(url, {
                headers,
                timeout: this.config.timeout,
                body: requestBody,
                nextFetchConfig: this.config.nextFetchConfig
            });
            this.panic = !!response?.body?.panic;
            let campaigns = null;
            if (response?.body?.campaigns) {
                campaigns = response.body.campaigns;
            }
            const troubleshooting = response?.body?.extras?.accountSettings?.troubleshooting;
            if (troubleshooting) {
                this.troubleshooting = {
                    startDate: new Date(troubleshooting.startDate),
                    endDate: new Date(troubleshooting.endDate),
                    timezone: troubleshooting.timezone,
                    traffic: troubleshooting.traffic
                };
            }
            return campaigns;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }
        catch (error) {
            const troubleshooting = new Troubleshooting({
                label: TroubleshootingLabel.GET_CAMPAIGNS_ROUTE_RESPONSE_ERROR,
                logLevel: LogLevel.ERROR,
                visitorId: visitor.visitorId,
                anonymousId: visitor.anonymousId,
                visitorSessionId: visitor.instanceId,
                traffic: 100,
                config: this.config,
                visitorContext: visitor.context,
                httpRequestBody: requestBody,
                httpRequestHeaders: headers,
                httpRequestMethod: 'POST',
                httpRequestUrl: url,
                httpResponseBody: error?.message,
                httpResponseHeaders: error?.headers,
                httpResponseCode: error?.statusCode,
                httpResponseTime: Date.now() - now
            });
            await this.trackingManager.addTroubleshootingHit(troubleshooting);
            const errorMessage = errorFormat(error.message || error, {
                url,
                headers,
                body: requestBody,
                duration: Date.now() - now
            });
            throw new Error(errorMessage);
        }
    }
}
